<template>

  <v-container>
    <v-row
      align="center"
      justify="center">
      <v-col v-if="isLoggedIn" xs="12" sm="10" md="8" xl="4">
        <v-alert
          type="error"
        >Sie sind bereits angemeldet.</v-alert>
      </v-col>
      <v-col v-if="!isLoggedIn" xs="12" sm="10" md="8" xl="4">
        <h1>Passwort zurücksetzen anfordern</h1>
        <v-card>
          <v-container>
            <v-row>
              <v-col v-if="!success">
                <v-form v-model="valid" action="POST" @submit.prevent="resetPW">
                    <v-row>
                      <v-col>
                        <v-text-field
                          name="email"
                          label="E-Mail"
                          id="email"
                          type="email"
                          :rules="rules.emailRules"
                          autocomplete="username"
                          v-model="authDetails.email"
                          required></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-btn color="accent" type="submit">Zurücksetzen</v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                    <v-col v-if="error">
                      <v-alert
                        type="error"
                        style="white-space:pre-wrap"
                      >{{error}}</v-alert>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
              <v-col v-if="success">
                <v-alert
                  type="success"
                  style="white-space:pre-wrap"
                >{{success}}</v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import gql from 'graphql-tag';
import rules from '../../helpers/rules';

const RESET_PW = gql`
mutation forgotPassword ($email: String!){
  forgotPassword(email: $email){
    ok
  }
}
`

export default {
  name: 'RequestPassword',
  valid: false,
  props: {
    isLoggedIn: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      valid: null,
      authDetails: {
        email: ''
      },
      error: null,
      success: null,
      rules:rules
    }
  },
  methods: {
    resetPW: function () {
      this.$apollo.mutate({mutation: RESET_PW, variables: { ...this.authDetails }}).then((data) => {
        // console.log(data);
        if(data.data.forgotPassword.ok){
          this.success = "Die Passwort Zurücksetzen-Email wurde verschickt. Bitte folgen Sie den Anweisungen der E-Mail."
        }else{
          throw(data);
        }

      }).catch((error) => {
        // Error
        let ermsg='';
        if(error.networkError && error.networkError.result){
          ermsg = JSON.stringify(error.networkError.result);
        }else{
          ermsg = error;
        }
        console.error(error);
        this.error = "Die Anfrage zum Zurücksetzen des Passworts hat einen Fehler verursacht. Bitte kontaktieren Sie ihren Administrator.\nFehlermeldung:\n"+ermsg;
        //localStorage.setItem('token', '');
        //this.$emit('changeLoginState');
      });
    }
  }
}
</script>
